import { Link } from "react-router-dom";

function RegionTile(props) {
    const { region } = props;

    return (
        <Link className="block border my-4 py-10 rounded-lg bg-cover bg-center" style={{ backgroundImage: `url(${region.image_url})` }} to={`/${region.slug}/`}>
            <div className="w-1/2 m-auto py-4 bg-slate-50 rounded-lg">
                <h2 className="text-lg text-center">{region.name}</h2>
            </div>
        </Link>
    );
}

export default RegionTile;
