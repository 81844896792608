import formats from "../utils/formats";
import apiClient from "./api-client";
import { GMAPENDPOINT } from "./constants";

async function getDistanceFromTwoPoints(origin, destination) {
    var response = await apiClient.doGet(formats.formatUrl(GMAPENDPOINT, { oLat: origin.lat, oLng: origin.lng, dLat: destination.lat, dLng: destination.lng }));

    response = response && response.rows[0] && response.rows[0].elements[0].distance.text;

    return response;
}

export default { getDistanceFromTwoPoints };
