import clsx from "clsx";

function TimeTables(props) {
    const { week } = props;

    const now = new Date().toLocaleString([], { weekday: "long" });

    return (
        <div className="py-4">
            {week.map(day =>
                <div className="text-gray-300 flex justify-between">
                    <span className={clsx({ "text-white font-bold": now == day.day_of_week_formatted.toLowerCase() })}>{day.day_of_week_formatted}</span>
                    <span className={clsx({ "text-white font-bold ": now == day.day_of_week_formatted.toLowerCase() })}>{day.hours}</span>
                </div>)}
        </div>
    );
}

export default TimeTables;
