import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import network from "../apis/network";
import { trackDataV2 } from "../apis/tracking";
import DropDownInfo from "../components/DropDownInfo";

function Borgo() {
    const { region, borgo } = useParams();

    const [village, setVillage] = useState({});

    const { t } = useTranslation();

    useEffect(() => {
        async function fetch() {
            const res = await network.doGetBorgoWithName(region, borgo);
            setVillage(res);

            trackDataV2(2, "k9i8jd78w", "viewborgo", { id: res.id }, false);
        }

        fetch();
    }, [])

    const onClickHandler = () => {
        trackDataV2(2, "k9i8jd78w", "viewguida", { id: village.id }, false);
    }

    const onDiscoverMoreHandler = () => {
        trackDataV2(2, "k9i8jd78w", "discovermore", { id: village.id }, false);
    }

    return (
        <div>
            <Helmet>
                <title>{`${village && village.name}: la storia, i locali e il patrimonio di uno dei Borghi più Belli d'Italia, scarica la guida enogastronomica ufficiale | Borghipiubelliditalia.mycia.it`}</title>
                <meta name="description" content={`Stai cercando informazioni sui locali ${village && village.name}? Entra in Borghipiubelliditalia.mycia.it e vieni a scaricare la guida enogastronomica digitale ufficiale di uno dei Borghi più Belli d'Italia.`} />
                <link rel="canonical" href={`https://borghipiubelliditalia.mycia.it/${region}/${borgo}`} />
            </Helmet>

            <div className="relative bg-borghi-red">
                <div className="h-44 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: `url(${village.image_url})`, "mixBlendMode": "multiply", "opacity": "1" }}></div>

                <Link to={`/${region}`} className="absolute top-1/2 left-[10%] -translate-x-1/2 -translate-y-1/2 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-borghi-red" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                </Link>

                <h1 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-center uppercase text-white">{village.name}</h1>
            </div>

            {/* <DropDownInfo borgo={village} /> */}

            <div className="space-y-24 px-6">
                <section className="mt-16 pt-8 pb-4 rounded-3xl border shadow-xl">
                    <div className="relative text-center">
                        <img className="m-auto -mt-20 w-40 h-40 rounded-full" src="/assets/borghi/ristoranti.jpeg" alt="piaceri e sapori del borgo" />
                        <h2 className="pt-4">{t("borgo.p_2")}</h2>
                        <Link className="absolute bottom-0 right-0 px-2" to={`/${region}/${borgo}/ristoranti`}>
                            <div className="flex items-center p-2 w-8 h-8 rounded-full bg-borghi-red">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 fill-white stroke-white" viewBox="0 0 512 512"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                            </div>
                        </Link>
                    </div>
                </section>

                <section className="pt-8 pb-4 rounded-3xl border shadow-xl">
                    <div className="relative text-center">
                        <img className="m-auto -mt-20 w-40 h-40 rounded-full" src="/assets/borghi/lo_spirito_del_luogo.png" alt="Immagine rappresentativa del borgo" />
                        {village && village.name && <p className="p-3">
                            <span className="font-bold">{t("borgo.p_4._1", { village: village.name })} </span>
                            <span>{t("borgo.p_4._2", { village: village.name })} </span>
                            <span className="font-bold">{t("borgo.p_4._3", { region: village.regione.name })} </span>
                            <span>{t("borgo.p_4._4", { village: village.name })}</span>
                        </p>}
                        <h2 className="pt-4">{t("borgo.p_1")}</h2>
                        <a className="absolute bottom-0 right-0 px-2" href={`https://borghipiubelliditalia.it/borgo/${borgo}/`} onClick={onDiscoverMoreHandler} target="_black" rel="noopener">
                            <div className="flex items-center p-2 w-8 h-8 rounded-full bg-borghi-red">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 fill-white stroke-white" viewBox="0 0 512 512"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                            </div>
                        </a>
                    </div>
                </section>

                <section className="pt-8 pb-4 rounded-3xl border shadow-xl">
                    <div className="relative text-center">
                        <img className="m-auto -mt-20 w-28 h-40" src="/assets/borghi/guida.jpg" alt="guida dei borghi più belli d'italia" />
                        <h2 className="pt-4">{t("borgo.p_3")}</h2>
                        <a className="absolute bottom-0 right-0 px-2" href="https://borghipiubelliditalia.it/guida-borghi/" target="_blank" onClick={onClickHandler}>
                            <div className="flex items-center p-2 w-8 h-8 rounded-full bg-borghi-red">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 fill-white stroke-white" viewBox="0 0 512 512"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                            </div>
                        </a>
                    </div>
                </section>
            </div>

            {village && village.restaurants &&
                <p className="block py-6 pb-4 text-center text-lg">Powered by <a className="font-bold underline" href={`https://www.mycia.it/restaurants?type=city&sort=asc&limit=20&city=${village.name}&search=${village.name}&lat=${village.restaurants[0].latitude}&lng=${village.restaurants[0].longitude}&page=1&use_range=1`} target="_blank" rel="noreferrer">MyCIA</a></p>
            }
        </div>
    );
}

export default Borgo;
