import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import BusinessesWrapper from "./pages/BusinessesWrapper";
import Region from "./pages/Region";
import Business from "./pages/Business";
import Home from "./pages/Home";
import Borgo from "./pages/Borgo";
import BorgoInfo from "./pages/BorgoInfo";
import "./App.css";

function AppRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/:region" element={<Region />} />
            <Route exact path="/:region/:borgo/" element={<Borgo />} />
            <Route exact path="/:region/:borgo/lo-spirito-del-luogo" element={<BorgoInfo />} />
            <Route exact path="/:region/:borgo/ristoranti" element={<BusinessesWrapper />} />
            <Route exact path="/:region/:borgo/ristoranti/:slug/menu" element={<Business />} />
        </Routes>
    );
}

export default AppRoutes;
