import React, { useEffect, useState } from 'react';
import formats from '../utils/formats';
import positionLogic from "../apis/position";
import { useParams } from 'react-router';

function Tile(props) {
    const { business: restaurant, userPosition, index: i } = props;

    const { region, borgo } = useParams();

    const [distance, setDistance] = useState("");

    useEffect(async () => {
        if (userPosition.lat && userPosition.lng) {
            const res = await positionLogic.getDistanceFromTwoPoints({ lat: userPosition.lat, lng: userPosition.lng }, { lat: restaurant.latitude, lng: restaurant.longitude });
            setDistance(res);
        }

    }, [userPosition]);

    return (
        <div className="my-8 px-3 py-1 flex items-center justify-between bg-white border rounded-lg" key={i}>
            <img src={restaurant.image_url || restaurant.cover_image} alt="Immagine rappresentativa del ristorante" className="object-cover w-14 h-14 border border-grey-300 rounded-full" />

            <div className="h-full">
                <div className="space-y-2">
                    <h2 className="uppercase font-extrabold leading-tight">{formats.truncate(restaurant.name, 18)}</h2>
                    <div className="ml-1 flex items-center">
                        <p className="w-2/3 text-clip font-marcia text-sm">{formats.truncate(restaurant.address, 28)}</p>
                        <p className="w-1/3 mr-2 text-center text-sm font-marcia border rounded-xl">
                            {distance ? distance : "..."}
                        </p>
                    </div>
                </div>

                {restaurant.promotions.length > 0 &&
                    <div className="mt-0.5 px-1 py-1 text-center bg-yellow-300 rounded-xl">
                        <p className="text-clip font-marcia text-xs">Sconto disponibile per <span className="font-bold">Amici dei Borghi</span></p>
                    </div>}
            </div>

            <a href={formats.formatUrl("/:region/:borgo/ristoranti/:slug/menu", { region, borgo, slug: restaurant.slug })} className="p-2 block text-white bg-borghi-red rounded-full shadow-2xl">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
            </a>
        </div>
    );
}

export default Tile;
