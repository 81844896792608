import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function SearchRegion(props) {
    const { className, regioni, setResult, setIsSearching } = props;

    const { t } = useTranslation();

    function search(e) {
        if (e.target.value != "" && e.target.value != null) {
            const regexp = new RegExp(e.target.value, 'i');
            const result = regioni.filter(borgo => regexp.test(borgo.name));
            setResult(result);
            setIsSearching(true);

        } else {
            setResult(regioni);
            setIsSearching(false);
        }
    }

    return (
        <div>
            <input
                className={className}
                placeholder={t("home.searchRegion.input")}
                onChange={search} />
        </div>
    )
}

export default SearchRegion;
