export const ENDPOINT = "https://api-ristoratori.mycia.it/v2/restaurants/?id=:id&lang=:lang";
export const TOKENENDPOINT = "https://api-ristoratori.mycia.it/v2/restaurants/?token=:token&lang=:lang";
export const GMAPENDPOINT = "https://api-ristoratori.mycia.it/googleapis/maps/api/distancematrix/json/?origins=:oLat,:oLng&destinations=:dLat,:dLng&mode=driving";
export const COURSESENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang"
export const NEWSLETTERENDPOINT = "https://api-ristoratori-v3.mycia.it/newsletter/subscriptions";
export const ANALYTICSENDPOINT = "https://loggermycia.sdch.develondigital.com/v1/logs/webapp";
export const BORGHIENDPOINT = "https://api-ristoratori-v3.mycia.it/borghi?lang=:lang";
export const BORGOENDPOINT = "https://api-ristoratori-v3.mycia.it/borghi/:id/?lang=:lang";
export const BORGONAMEENDPOINT = "https://api-ristoratori-v3.mycia.it/borghi/:region/:village?lang=:lang";
export const SEOENDPOINT = "https://api-ristoratori-v3.mycia.it/borghi/list/region/:region";
