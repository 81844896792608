import { useEffect, useState } from "react";
import deviceLanguage from "../../utils/device-language";
import dishesClient from "../../apis/dishes";

function useBusinessDishes(props) {
    const { id } = props;

    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function handleBusinessDishesLoad() {
            const res = await dishesClient.doGet(id, deviceLanguage.getApiLanguage());
            setDishes(res.dishes);
            setLoading(false);
        }

        handleBusinessDishesLoad();
    }, [id]);

    return { loading, dishes };
}

export default useBusinessDishes;
