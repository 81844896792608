import apiClient from "./api-client";
import formats from "../utils/formats";
import { COURSESENDPOINT } from "./constants";

async function doGet(id, lang) {
    var response = await apiClient.doGet(formats.formatUrl(COURSESENDPOINT, { "id": id, "lang": lang }));

    return response;
}

export default { doGet };
