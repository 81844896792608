import formats from '../utils/formats';

async function doGet(url, config = {}) {
    const credentials = {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
    }

    var config = {
        "method": "GET",
        ...config,
        "headers": {
            "Accept": "application/json",
            ...config && config.headers,
            "Authorization": `Basic ${formats.encodeBase64(`${credentials.username}:${credentials.password}`)}`,
        },
    };

    try {
        const response = await window.fetch(url, config);
        return response.json();
    } catch (response) {
        var message = await response.text();
        return Promise.reject(new Error(message));
    }
}

async function doPost(url, data = {}, config = {}) {
    const credentials = {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
    }

    var headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }

    var config = {
        "method": "POST",
        ...config,
        "headers": {
            ...headers,
            ...config && config.headers,
            "auth": `Basic ${formats.encodeBase64(`${credentials.username}:${credentials.password}`)}`,
        },
        "body": data,
        "redirect": 'follow'
    };

    try {
        const response = await window.fetch(url, config);
        return response.json();
    } catch (response) {
        var message = await response.text();
        return Promise.reject(new Error(message));
    }

}

export default { doGet, doPost }
