import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function SearchBorgo(props) {
    const { className, borghi, setResult, setIsSearching } = props;

    const [searchText, setSearchText] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        async function fetchSearch() {
            const regexp = new RegExp(searchText, 'i');
            const result = borghi.filter(borgo => regexp.test(borgo.name));
            setResult(result);
            setIsSearching(true);
        }

        if (searchText != "" && searchText != null) {
            fetchSearch();
        } else {
            setResult(borghi);
            setIsSearching(false);
        }
    }, [searchText]);

    return (
        <div>
            <input
                className={className}
                placeholder={t("regione.searchBorgo.input")}
                onChange={e => (setSearchText(e.target.value))} />
        </div>
    )
}

export default SearchBorgo;
