import React, { useEffect, useState } from "react";
import useGeoPosition from "../logic/hooks/useGeoPosition";
import { trackDataV2 } from "../apis/tracking";
import { Link } from "react-router-dom";
import BusinessesWrapperTabs from "../components/BusinessesWrapperTabs";
import { useParams } from "react-router";
import network from "../apis/network";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function BusinessesWrapper() {
    const { region, borgo } = useParams();

    const [restaurants, setRestaurants] = useState([]);
    const [village, setVillage] = useState({});

    const userPosition = useGeoPosition();
    const { t } = useTranslation();

    useEffect(() => {
        async function fetch() {
            const response = await network.doGetBorgoWithName(region, borgo);
            setVillage(response);
            setRestaurants(response.restaurants);

            trackDataV2(2, "k9i8jd78w", "viewpiaceriesapori", { id: response.id }, false);
        }

        fetch();
    }, []);

    return (
        <div className="grid">
            <Helmet>
                <title>{`${village && village.name}: I migliori ristoranti dove mangiare nei Borgi più belli d'Italia, scarica la guida enogastronomica ufficiale | Borghipiubelliditalia.mycia.it`}</title>
                <meta name="description" content={`Stai cercando i migliori ristoranti di ${village && village.name}? Visita il sito Borghipiubelliditalia.mycia.it per conoscere tutti i locali da visitare. Scarica la guida enogastronomica ufficiale.`} />
                <link rel="canonical" href={`https://borghipiubelliditalia.mycia.it/${region}/${borgo}/ristoranti`} />
            </Helmet>

            <div className="relative bg-borghi-red">
                <div className="h-44 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: `url(${village.image_url})`, "mixBlendMode": "multiply", "opacity": "1" }}></div>

                <Link to={`/${region}/${borgo}`} className="absolute top-1/2 left-[10%] -translate-x-1/2 -translate-y-1/2 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-borghi-red" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                </Link>

                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white">
                    <h1 className="uppercase text-2xl">{village.name}</h1>
                    <p className="text-white text-center text-xl">{t("businessWrapper.p")}</p>
                </div>
            </div>

            <div className="group-list relative bg-white pb-20">
                {userPosition.msg &&
                    <p className="mt-4 text-sm text-center text-red-400">{userPosition.msg}</p>}

                <div className="px-4">
                    <BusinessesWrapperTabs borgo={village} restaurants={restaurants} userPosition={userPosition} />
                </div>
            </div>

            {village && village.restaurants &&
                <p className="block pb-4 text-center text-lg">Powered by <a className="font-bold underline" href={`https://www.mycia.it/restaurants?type=city&sort=asc&limit=20&city=${village.name}&search=${village.name}&lat=${village.restaurants[0].latitude}&lng=${village.restaurants[0].longitude}&page=1&use_range=1`} target="_blank" rel="noreferrer">MyCIA</a></p>
            }
        </div>
    );
}

export default BusinessesWrapper;
