import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function SearchBar(props) {
    const { className, searchText, setSearchText, restaurants, setSearchResults, setIsSearching } = props;

    const { t } = useTranslation();

    useEffect(() => {
        function fetchSearch() {
            const regexp = new RegExp(searchText, 'i');
            const result = restaurants.filter(restaurant => regexp.test(restaurant.name));
            setSearchResults(result);
            setIsSearching(true);
        }

        if (searchText != "" && searchText != null) {
            setIsSearching(false);
            fetchSearch();
        }
    }, [searchText]);

    return (
        <div>
            <input
                className={className}
                placeholder={t("businessesWrapperTabs.searchBar.input_1")}
                onChange={e => (setSearchText(e.target.value))} />
        </div>
    )
}

export default SearchBar;
