import client from "./api-client";
import formats from "../utils/formats";
import { ENDPOINT, TOKENENDPOINT } from "./constants";

async function getBusiness(id, lang) {
    const isNumeric = /^\d{1,5}$/.test(id);

    let url = isNumeric ? ENDPOINT : TOKENENDPOINT;
    let config = isNumeric ? { "lang": lang, "id": id } : { "lang": lang, "token": id };

    let response = await client.doGet(formats.formatUrl(url, config));

    return response;
}

export default { getBusiness }
