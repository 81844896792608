import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import BorgoTile from "../components/BorgoTile";
import SearchBorgo from "../components/SearchBorgo";
import network from '../apis/network';
import { trackDataV2 } from "../apis/tracking";
import { Helmet } from "react-helmet";

function Region() {
    const { region } = useParams();

    const [localizedBorghi, setLocalizedBorghi] = useState([]);
    const [regione, setRegione] = useState({});
    const [result, setResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        async function fetch() {
            const borghi = await network.doGetBorghi();
            const aux = borghi.filter(borgo => borgo.regione.slug == region);
            aux.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            setLocalizedBorghi(aux);
            setRegione(aux[0].regione);
            setLoaded(true);

            trackDataV2(2, "k9i8jd78w", "viewregione", { id: aux[0].regione.id }, false);
        }

        fetch();
    }, [])

    return (
        loaded ?
            <React.Fragment>
                <Helmet>
                    <title>{`${regione && regione.name}: scopri i locali dei Borghi più Belli d'Italia della regione, scarica la guida enogastronomica digitale ufficiale | Borghipiubelliditalia.mycia.it`}</title>
                    <meta name="description" content={`Stai cercando i ristornati nei Borghi più Belli d'Italia del ${regione && regione.name}? Entra in Borghipiubelliditalia.mycia.it e vieni ha scoprire i migliori ristoranti. Scarica la guida enogastronomica digitale.`} />
                    <link rel="canonical" href={`https://borghipiubelliditalia.mycia.it/${region}`} />
                </Helmet>

                <div>
                    <div className="relative py-28 bg-cover bg-center" style={{ backgroundImage: `url(${regione.image_url})` }}>
                        <div className="m-auto w-1/2 py-6 bg-[#bf0d17e0] rounded-lg">
                            <Link to="/" className="absolute top-1/2 left-[10%] -translate-x-1/2 -translate-y-1/2 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path xmlns="http://www.w3.org/2000/svg" d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /></svg>
                            </Link>

                            <h1 className="text-lg text-center text-white">{regione.name}</h1>
                        </div>
                    </div>

                    <SearchBorgo borghi={localizedBorghi} setResult={setResult} setIsSearching={setIsSearching} className="relative block m-auto w-[90%] p-3 -mt-6 border-2 rounded-lg" />

                    <div className="p-8">
                        {isSearching ?
                            result.map((borgo, i) =>
                                <BorgoTile borgo={borgo} key={i} />
                            ) :
                            localizedBorghi.map((borgo, i) =>
                                <BorgoTile borgo={borgo} key={i} />
                            )}
                    </div>

                    <p className="block pb-4 text-center text-lg">Powered by <a className="font-bold underline" href={`https://www.mycia.it`} target="_blank" rel="noreferrer">MyCIA</a></p>
                </div>
            </React.Fragment> :

            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", display: "block" }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="translate(20 50)">
                    <circle cx="0" cy="0" r="6" fill="#bf0d17">
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(40 50)">
                    <circle cx="0" cy="0" r="6" fill="#d1493a">
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(60 50)">
                    <circle cx="0" cy="0" r="6" fill="#e1705e">
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(80 50)">
                    <circle cx="0" cy="0" r="6" fill="#ed9484">
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g>
            </svg>
    );
}

export default Region;
