import React, { createContext, useContext, useEffect, useState } from 'react';
import deviceLanguage from "../../utils/device-language";
import businessClient from "../../apis/business";

export const BusinessContext = createContext({});

export const BusinessProvider = ({ children }) => {
    const [business, setBusiness] = useState({});
    const [token, setToken] = useState(null);

    useEffect(() => {
        async function handleBusinessLoad() {
            const res = await businessClient.getBusiness(token, deviceLanguage.getApiLanguage());
            setBusiness(res)
        }

        token && handleBusinessLoad();
    }, [token])

    return (
        <BusinessContext.Provider value={{ business, setToken }}>
            {children}
        </BusinessContext.Provider>
    );
};

export const useGetBusiness = () => useContext(BusinessContext);
