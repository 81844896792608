import DrinkModal from "./DrinkModal";

function Drinks(props) {
    const { drinks } = props;

    return (
        <div className="w-full">
            {drinks.map(drink =>
                <DrinkModal drink={drink} />
            )}
        </div>
    )
}

export default Drinks;
