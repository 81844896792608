import deviceLanguage from "../utils/device-language";
import formats from "../utils/formats";
import apiClient from "./api-client";
import { BORGHIENDPOINT, BORGOENDPOINT, BORGONAMEENDPOINT } from "./constants";

async function doGetBorghi() {
    var response = await apiClient.doGet(formats.formatUrl(BORGHIENDPOINT, { "lang": deviceLanguage.getApiLanguage() }));

    return response.data;
}

async function doGetBorgo(id) {
    var response = await apiClient.doGet(formats.formatUrl(BORGOENDPOINT, { "lang": deviceLanguage.getApiLanguage(), "id": id }));

    return response && response.data && response.data.pop();
}

async function doGetBorgoWithName(region, village) {
    var response = await apiClient.doGet(formats.formatUrl(BORGONAMEENDPOINT, { "lang": deviceLanguage.getApiLanguage(), region, village }));

    return response && response.data && response.data.pop();
}

export default {
    doGetBorghi,
    doGetBorgo,
    doGetBorgoWithName,
}
