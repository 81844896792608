import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import * as TabsCore from '@radix-ui/react-tabs';
import clsx from "clsx";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import network from "../apis/network";
import { trackDataV2 } from "../apis/tracking";
import { Helmet } from "react-helmet";
import formats from "../utils/formats";

function BorgoInfo() {
    const { region, borgo } = useParams();

    const [village, setVillage] = useState({ contents: [] });
    const [selectedTab, setSelectedTab] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        async function fetch() {
            const response = await network.doGetBorgoWithName(region, borgo);
            setVillage(response);

            trackDataV2(2, "k9i8jd78w", "viewspiritoluogo", { id: response.id }, false);
        }

        fetch();
    }, []);

    const onclickHandler = () => {
        trackDataV2(2, "k9i8jd78w", "discovermore", { id: village.id }, false);
    }

    return (
        <div>
            <Helmet>
                <title>{`${village && village.name}: Informazioni sui ristoranti di uno dei Borghi più belli d'Italia, scarica la guida enogastronomica ufficiale | Borghipiubelliditalia.mycia.it`}</title>
                <meta name="description" content={`Cerchi indicazioni sulla storia e locali di ${village && village.name}? Vieni a scoprire nel sito Borghipiubelliditalia.mycia.it tutte i dettagli del borgo e scarica la guida enogastronomica digitale ufficiale.`} />
                <link rel="canonical" href={`https://borghipiubelliditalia.mycia.it/${region}/${borgo}/lo-spirito-del-luogo`} />
            </Helmet>

            <div className="relative bg-borghi-red">
                <div className="h-44 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: `url(${village.image_url})`, "mixBlendMode": "multiply", "opacity": "1" }}></div>

                <Link to={`/${region}/${borgo}`} className="absolute top-1/2 left-[10%] -translate-x-1/2 -translate-y-1/2 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-borghi-red" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                </Link>

                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white">
                    <h1 className="uppercase text-2xl">{village.name}</h1>
                    <p className="text-white text-center text-xl">{t("borgoInfo.p_1")}</p>
                </div>
            </div>

            <div className="px-6">
                <TabsCore.Root className="relative" defaultValue="tab0">
                    <TabsCore.List aria-label="TabsCore" className="bar overflow-x-scroll space-x-2 whitespace-nowrap flex py-6">
                        {village.contents.map((content, i) =>
                            content.label != "musei" &&
                            <TabsCore.Trigger value={`tab${i}`} className={clsx("px-4 py-2 whitespace-nowrap font-bold border border-borghi-red rounded-md shadow-lg", { "text-white bg-borghi-red border-borghi-red shadow-none": selectedTab == i })} onClick={() => setSelectedTab(i)}>{t(`borgoInfo.${content.label}`)}</TabsCore.Trigger>
                        )}

                        <TabsCore.Trigger value={`tabStatic`} className={clsx("px-4 py-2 whitespace-nowrap font-bold border border-borghi-red rounded-md shadow-lg", { "text-white bg-borghi-red border-borghi-red shadow-none": selectedTab == 10 })} onClick={() => setSelectedTab(10)}>{t(`borgoInfo.scopri.trigger`)}</TabsCore.Trigger>
                    </TabsCore.List>

                    {village.contents.map((content, i) => (
                        content.label != "musei" &&
                        <TabsCore.Content value={`tab${i}`} className="outline-none pb-10">
                            <section>
                                {parse(formats.truncate(content.body, 220))}

                                <a className="block px-6 py-3 m-1 my-6 text-center text-white bg-borghi-red rounded-full" href={`https://borghipiubelliditalia.it/borgo/${borgo}/`} target="_blank" rel="noopener" onClick={onclickHandler}>SCOPRI DI PIU</a>
                            </section>
                        </TabsCore.Content>
                    ))}

                    <TabsCore.Content value={`tabStatic`} className="outline-none">
                        <div className="pb-10">
                            <div className="flex items-center">
                                <img className="w-24" src="/assets/borghi/magazine.jpg" alt="magazine dei borghi più belli d'italia" />
                                <p className="px-5 text-xl">{t("borgoInfo.scopri.p_1")}</p>
                            </div>

                            <a className="block w-full px-6 py-3 m-1 my-6 uppercase text-center text-lg text-white bg-borghi-red rounded-full" href="https://borghipiubelliditalia.it/magazine/#acquisto_paypal" target="_blank" rel="noopener">{t("borgoInfo.scopri.a_1")}</a>

                            <div className="space-y-6">
                                <h2 className="font-black uppercase">{t("borgoInfo.scopri.p_2")}</h2>

                                <p>{t("borgoInfo.scopri.p_3._1")}<a className="text-borghi-red" href="www.borghipiubelliditalia.it">{t("borgoInfo.scopri.p_3._2")}</a></p>

                                <img className="mx-auto" src="/assets/borghi/app.png" alt="app dei borghi più belli d'italia" />

                                <a className="block w-full px-6 py-3 m-1 my-6 uppercase text-center text-lg text-white bg-borghi-red rounded-full" href="https://borghipiubelliditalia.it/guida-borghi/" target="_blank" rel="noopener">{t("borgoInfo.scopri.a_2")}</a>
                            </div>
                        </div>
                    </TabsCore.Content>
                </TabsCore.Root>
            </div>
        </div>
    );
}

export default BorgoInfo;
