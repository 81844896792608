import PhotoModal from "./PhotoModal";

function Gallery(props) {
    const { business } = props

    return (
        <div className="relative flex overflow-x-scroll space-x-1 px-2">
            {business.images_gallery.map((image, idx) =>
                <div className="my-6 w-32 whitespace-no-wrap">
                    <PhotoModal business={business} image={image} idx={idx} />
                </div>
            )}
        </div>
    )
}

export default Gallery;
