import { Link } from "react-router-dom";

function BorgoTile(props) {
    const { borgo } = props;

    return (
        <Link className="block border rounded-lg my-4 py-10 bg-cover bg-center" style={{ backgroundImage: `url(${borgo.image_url})` }} to={`/${borgo.regione.slug}/${borgo.slug}/`}>
            <div className="m-auto w-1/2 py-4 bg-slate-50 rounded-lg">
                <h2 className="text-lg text-center">{borgo.name}</h2>
            </div>
        </Link>
    );
}

export default BorgoTile;
