import AppRoutes from './AppRoutes';
import './App.css';

function App() {
  return (
    <div className="App max-w-md m-auto bg-white left-0 right-0 top-0 bottom-0 flex absolute flex-col justify-between overflow-auto z-0" >
      <AppRoutes />
    </div>
  );
}

export default App;
