import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import network from "../apis/network";
import { trackDataV2 } from "../apis/tracking";
import RegionTile from "../components/RegionTile";
import SearchRegion from "../components/SearchRegion";

function Home() {
    const [regioni, setRegioni] = useState([]);
    const [result, setResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        trackDataV2(2, "k9i8jd78w", "viewhome", {}, false);
    }, []);

    useEffect(() => {
        async function getData() {
            let aux = [];
            const borghi = await network.doGetBorghi();
            borghi.forEach((borgo) => {
                let b = aux.find(reg => reg.id == borgo.regione.id)

                !b && aux.push(borgo.regione);
            });

            aux.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            setRegioni(aux);
        }

        getData();
    }, [])

    const onClickHandler = () => {
        trackDataV2(2, "k9i8jd78w", "viewguida", {}, false);
    }

    return (
        <div>
            <Helmet>
                <title>Scopri regioni, paesi e ristoranti dei Borghi più Belli d'Italia, utilizza la Guida Enogastronomica Digitale ufficiale.</title>
                <meta name="description" content="Stai cercando i locali all’interno dei Borghi più Belli d'Italia? Entra nella Guida Digitale e vieni a scoprire tutto il fascino autentico dei paesi e della cultura enogastronomica d'Italia." />
                <link rel="canonical" href="https://borghipiubelliditalia.mycia.it/" />
            </Helmet>

            <div className="py-10 bg-borghi-red">
                <img className="m-auto" src="/assets/borghi/home.png" alt="logo borghi più belli d'Italia" />
            </div>

            <SearchRegion className="block m-auto w-[90%] p-3 -mt-6 border-2 rounded-lg" regioni={regioni} setResult={setResult} setIsSearching={setIsSearching} />

            <div className="p-8">
                {isSearching ?
                    result.map((regione, i) =>
                        <RegionTile region={regione} key={i} />
                    ) :
                    regioni.map((regione, i) =>
                        <RegionTile region={regione} key={i} />
                    )}
            </div>

            {regioni.length > 0 || result.length > 0 ?
                <a className="relative flex items-center mx-8 mb-6" href="https://borghipiubelliditalia.it/guida-borghi/" target="_blank" onClick={onClickHandler}>
                    <img className="z-10 w-10 mx-2" src="/assets/borghi/guida.jpg" alt="Guida dei borghi più belli d'Italia" />
                    <p className="z-10 fontthin text-sm text-white">{t("home.p")}</p>
                    <div className="absolute w-full py-6 border rounded-lg bg-borghi-red"></div>
                </a> : <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", display: "block" }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)">
                        <circle cx="0" cy="0" r="6" fill="#bf0d17">
                            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        </circle>
                    </g><g transform="translate(40 50)">
                        <circle cx="0" cy="0" r="6" fill="#d1493a">
                            <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        </circle>
                    </g><g transform="translate(60 50)">
                        <circle cx="0" cy="0" r="6" fill="#e1705e">
                            <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        </circle>
                    </g><g transform="translate(80 50)">
                        <circle cx="0" cy="0" r="6" fill="#ed9484">
                            <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        </circle>
                    </g>
                </svg>}

            <div className="pb-6 px-8 text-sm text-center">
                <p>Il tuo locale è in uno dei borghi più belli d'italia?</p>
                <a className="block w-full text-borghi-red underline" href="https://mycontactlessmenu.cartaidentitalimentare.com/guida-borghi-ditalia/" target="_blank">Compila il form per far parte della guida</a>
            </div>

            <p className="pb-4 text-center text-lg">Powered by <a href="https://mycia.it" target="_blank" className="font-bold underline">MyCIA</a></p>
        </div>
    );
}

export default Home;
