import * as TabsCore from '@radix-ui/react-tabs';
import DishCourses from './DishCourses';
import DrinkCourses from './DrinkCourses';
import clsx from "clsx";
import { useEffect, useState } from 'react';
import { trackDataV2 } from '../apis/tracking';
import { useTranslation } from 'react-i18next';

function BusinessTabs(props) {
    const { business } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        if (business && business.courses && business.courses.length == 0) setSelectedTab(1);
    }, [business])

    const onValueChangeHandler = (value) => {
        value == "tab1" ?
            trackDataV2(business.id, business.token, "viewrestaurantdishes", {}, false) :
            trackDataV2(business.id, business.token, "viewrestaurantdrinks", {}, false);
    }

    return (
        (business && ((business.courses && business.courses.length > 0) || (business.beverages && business.beverages.length > 0))) ?
            <TabsCore.Root onValueChange={onValueChangeHandler} defaultValue={business.courses && business.courses.length == 0 ? "tab2" : "tab1"} className="relative">
                <TabsCore.List aria-label="TabsCore" className="flex px-4 py-4 space-x-4">
                    {business.courses && business.courses.length > 0 &&
                        <TabsCore.Trigger value="tab1" className={clsx("w-1/2 px-4 py-1 whitespace-nowrap font-bold border rounded-full border-black", { "text-white bg-black": selectedTab == 0 })} onClick={() => setSelectedTab(0)}>{t("businessTabs.button_1")}</TabsCore.Trigger>}

                    {business.beverages && business.beverages.length > 0 &&
                        <TabsCore.Trigger value="tab2" className={clsx("w-1/2 px-4 py-1 whitespace-nowrap font-bold border rounded-full border-black", { "text-white bg-black": selectedTab == 1 })} onClick={() => setSelectedTab(1)}>{t("businessTabs.button_2")}</TabsCore.Trigger>}
                </TabsCore.List>

                {business.courses && business.courses.length > 0 &&
                    <TabsCore.Content value="tab1" className="outline-none">
                        <DishCourses business={business} />
                    </TabsCore.Content>}

                {business.beverages && business.beverages.length > 0 &&
                    <TabsCore.Content value="tab2" className="outline-none">
                        <DrinkCourses business={business} />
                    </TabsCore.Content>}

            </TabsCore.Root> :
            <p className="relative px-3 py-6 text-center font-bold">Il ristorante non ha ancora caricato il suo menu</p>
    )
}

export default BusinessTabs;
